<template>
  <ion-page>
    <div class="bg-white" v-if="access === `accepted` || access === null">
          <ion-tabs>
    <ion-header>
      <ion-toolbar>
        <div class="w-full flex flex-row p-2 justify-between items-center">
          <img src="../img/logo.png" alt="" class="w-32">
          <label for="callme" class="flex flex-col items-center">
            <i class="fa-solid fa-circle-exclamation text-xl"></i>
            <p class="text-xs italic">Llámame</p>
          </label>
        </div>
      </ion-toolbar>
    </ion-header>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="tab1" href="/tabs/tab1">
          <i class="fa-solid fa-ticket text-xl"></i>
          <ion-label>Tickets</ion-label>
        </ion-tab-button>
          
        <ion-tab-button tab="tab2" href="/tabs/tab2">
          <i class="fa-solid fa-file-contract text-xl"></i>
          <ion-label>Documentos</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab5" href="/tabs/tab5">
          <i class="fa-solid fa-signature text-xl"></i>
          <ion-label>Firmas</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab4" href="/tabs/tab4" class="hidden">
          <i class="fa-solid fa-receipt text-xl"></i>
          <ion-label>Facturas</ion-label>
        </ion-tab-button>
        
        <ion-tab-button tab="tab3" href="/tabs/tab3">
          <i class="fa-solid fa-user text-xl"></i>
          <ion-label>Usuario</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
    </div>
  </ion-page>
  <!-- Put this part before </body> tag -->
  <input type="checkbox" id="callme" class="modal-toggle" />
  <div class="modal">
    <div class="modal-box">
      <h3 class="font-bold text-lg text-red-600"><i class="fa-solid fa-triangle-exclamation"></i> Solicitud de llamada urgente.</h3>
      <p class="pb-4">¿Está usted seguro de que desea solicitar una llamada de urgencia?</p>
      <div class="modal-action">
        <label @click="enviarAlerta" for="callme" class="btn bg-red-600 border-red-600">solicitar llamada</label>
        <label for="callme" class="btn">mejor no</label>
      </div>
    </div>
  </div>
</template>

<script>
import { Preferences } from '@capacitor/preferences';
import { enviarMail, baseConnect } from "../db/db"
import { defineComponent } from 'vue';
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonPage, IonRouterOutlet, IonHeader, IonToolbar, toastController } from '@ionic/vue';
import { ellipse, square, triangle } from 'ionicons/icons';

export default defineComponent({
  name: 'TabsPage',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonPage, IonRouterOutlet, IonHeader, IonToolbar },
  setup() {
    return {
      ellipse, 
      square, 
      triangle,
    }
  },
  data(){
    return{
      access : "accepted"
    }
  },
  async mounted(){
      let index = await Preferences.get({ key : "index"})
      let datos = await baseConnect(`user/${index.value}/access`)
      this.access = datos
  },
  methods:{
    async enviarAlerta(){
      let datos = await baseConnect("empresa")
      console.log(datos)
      if(datos === null || datos.email2 === null){
        this.presentToast("No se puede solicitar una llamada de urgencia, por favor, contacte con la empresa. 😔")
      }else{
        this.presentToast("Solicitando llamada, espere...")
        let nombre = await Preferences.get({ key: 'name' })
        let nie = await Preferences.get({ key: 'nie' })
        let phone = await Preferences.get({ key: 'phone' })
        let msg1 = `Solicitud urgente de llamada.`
        let msg2 = `
        <p>${nombre.value} ha solicitado una llamada urgente.</p>
        <p>El número de teléfono del solicitante es ${phone.value}</p>
        `
        let footer = `${datos.nombre}. Dirección ${datos.direccion}. Email ${datos.email} || Teléfono ${datos.telef}`
        await enviarMail(datos.email2, msg1, msg2, footer)
        this.presentToast("Llamada solicitada. Por favor, sea paciente. ☺️")
      }
    },
    async presentToast(msg) {
        const toast = await toastController.create({
            message: msg,
            duration: 2000,
            position: "top"
        });

        await toast.present();
    },
  }
});
</script>
