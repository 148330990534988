import CryptoJS from "crypto-js"
import { baseConnectDelete, baseConnectPut, baseConnectSearch} from "./db"
// Import the functions you need from the SDKs you need

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
    apiKey: "AIzaSyAoPAjXku6J9nGAIDxJJpLS24Ru79IZviM",
    databaseURL: "https://asesor-27bd7-default-rtdb.europe-west1.firebasedatabase.app",
    authDomain: "asesor-27bd7.firebaseapp.com",
    projectId: "asesor-27bd7",
    storageBucket: "asesor-27bd7.appspot.com",
    messagingSenderId: "586913152264",
    appId: "1:586913152264:web:a550fb1ec824b1655340bd"
  };
 initializeApp(firebaseConfig)


export async function firebaseAutenticate(where, query, objeto){
  let data = ""
  try {
    const response = await baseConnectSearch(where, query, objeto.email)
    if(response === null){
      const encriptPassword = CryptoJS.AES.encrypt(objeto.password, "cardiosalus").toString()
      objeto.password  = encriptPassword
      await baseConnectPut(`user/${objeto.index}`, objeto)
      data = true
    }else{
      data = false
    }
    return data
  } catch (error) {
    console.log(error)
    data = false
    return data
  }
}

export async function fireBaseSignIn(where, query, email, pass){
  let data = ""
  try {
    const response = await baseConnectSearch(where, query, email)
    console.log(response)
    if(response === null){
      data = false
    }else{
      const responseArray = Object.values(response)
      const bytes  = CryptoJS.AES.decrypt(responseArray[0].password, 'cardiosalus');
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      console.log(originalText)
      if(originalText === pass){
        data = responseArray[0]
      }else{
        data = false
      }
    }
    return data
  } catch (error) {
    console.log(error)
    data = false
  }
  return data
}

export async function resetPassword(index, password){
  let data = ""
  try {
    const encriptPassword = CryptoJS.AES.encrypt(password, "cardiosalus").toString()
    await baseConnectPut(`user/${index}/password`, encriptPassword)
    data = true
    return data
  } catch (error) {
    console.log(error)
    data = false
    return data
  }
}

export async function userDelete(index){
  let data = ""
  try {
    await baseConnectDelete(`user/${index}`)
    data = true
    return data
  } catch (error) {
    data = false
    return data
  }
}

export async function emailUpdate(index, email){
  let data = ""
  try {
    await baseConnectPut(`user/${index}/email`, email)
    data = true
    return data
  } catch (error) {
    data = false
    return data
  }
}