import { getDatabase, ref, set, get, remove , child, update, query, orderByChild, equalTo } from "firebase/database"
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAoPAjXku6J9nGAIDxJJpLS24Ru79IZviM",
  databaseURL: "https://asesor-27bd7-default-rtdb.europe-west1.firebasedatabase.app",
  authDomain: "asesor-27bd7.firebaseapp.com",
  projectId: "asesor-27bd7",
  storageBucket: "asesor-27bd7.appspot.com",
  messagingSenderId: "586913152264",
  appId: "1:586913152264:web:a550fb1ec824b1655340bd"
};
firebase.initializeApp(firebaseConfig)
const db = getDatabase()

const mailserver = "http://46.183.117.15:4000/enviarmail"

export async function baseConnect(where){
    let datos = ""
    const dbref = ref(db)
    await get(child(dbref, where))
        .then(data => {
            datos = data.val()
        })
    return datos
}

export async function baseConnectPut(where, post){
    await set(ref(db, where), post)
        .then(() => {
            console.log("Conexión realizada con éxito")
        })
        .catch(err => {
            console.log(err)
        })
}

export async function baseConnectDelete(where){
    await remove(ref(db, where))
    .then(() => {
        console.log("Borrado correctamente")
    })
    .catch(err => {
        console.log(err)
    })
}

export async function baseConnectSearch(where, query1, what){
    let datareturn = ""
    const dbref = await query(ref(db, where), orderByChild(query1), equalTo(what))
    await get(dbref, "user")
        .then((data) => {
            datareturn = data.val()
        })
        .catch((err) => {
            console.log(err)
            datareturn = false
        })
    return datareturn
}

// eslint-disable-next-line
export async function enviarMail(where, asunto, cuerpo, footer){
    let datos = ""
    await fetch(`${mailserver}?donde=${where}&asunto=${encodeURIComponent(asunto)}&cuerpo=${encodeURIComponent(cuerpo)}&footer=${encodeURIComponent(footer)}`)
        .then(() => {
            datos = "Email enviado"
        })
        .catch(err => {
            datos = "Error al enviar mail."
            console.log(err)
        })
    return datos
}