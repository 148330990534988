<template>
  <ion-page>
    <ion-content :fullscreen="true">
        <div class="flex flex-col justify-center items-center h-full animate__animated animate__fadeIn">
            <div class="text-gray-700 mb-10">
                <p class="text-4xl font-extrabold text-green-600"><i class="fa-solid fa-file-contract"></i> jano</p>
                <p class="font-bold text-xs">Recuperar contraseña</p>
            </div>
            <div>
                <div class="my-2">
                    <label class="label">
                        <span class="label-text">Introducir email</span>
                    </label>
                    <label class="input-group">
                        <span><i class="fa-solid fa-envelope text-lg text-gray-700"></i></span>
                        <input v-model="email" type="text" placeholder="miemail@miemail.com" class="input input-bordered" />
                    </label>
                </div>
                <div class="my-2">
                    <label class="label">
                        <span class="label-text">Introducir nie/pasaporte</span>
                    </label>
                    <label class="input-group">
                        <span><i class="fa-solid fa-envelope text-lg text-gray-700"></i></span>
                        <input v-model="nie" type="text" placeholder="235679818X" class="input input-bordered" />
                    </label>
                </div>
                <button @click="sendData" class="btn w-full bg-gradient-to-tr from-green-500 to-green-700 mt-8">Recuperar <i class="fa-solid fa-user-check ml-2"></i></button>
                <button @click="goBack" class="btn w-full  mt-2">atrás <i class="fa-solid fa-rotate-left ml-2"></i></button>
            </div>
        </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRouter } from "vue-router"
import { baseConnect, enviarMail } from "../db/db"
import { IonPage, IonContent, toastController } from '@ionic/vue';
export default {
    components:{
        IonPage, IonContent
    },
    data(){
        return{
            nie : "",
            email : "",
            router : useRouter()
        }
    },
    methods:{
        async sendData(){
            if(this.nie === "" || this.email === ""){
                this.presentToast("Porfavor, rellene los datos correctamente. 🤔")
            }else{
                let datos = await baseConnect(`user`)
                datos.forEach(async (element,index) => {
                    if(element.nie === this.nie.toUpperCase() && element.email === this.email.toLowerCase()){
                        console.log(element)
                        let text = `Jano informa, su usuario es ${element.email} y su contraseña es ${element.pass}`
                        let datos  = await enviarMail(element.email, "Recordatorio de contraseña Jano", text)
                        this.presentToast(datos)
                        this.router.push("/")
                    }else{
                        if(index === (datos.length - 1)){
                            this.presentToast("Algo a sucedido, usuario no encontrado. 🤔")
                        }
                    }
                })
            }
        },
        async presentToast(msg) {
            const toast = await toastController.create({
                message: msg,
                duration: 2000,
                position: "top"
            });

            await toast.present();
        },
        goBack(){
            this.router.push("/")
        }
    }
}
</script>

<style>

</style>