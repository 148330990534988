<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <div v-if = "login === true" class="flex flex-col justify-center items-center h-full animate__animated animate__fadeIn">
                <div class="text-gray-700 mb-10">
                    <img src="../img/logo.png" alt="" class="w-52">
                </div>
                <div>
                    <p class="text-green-600 text-sm text-center mb-2">Introducir cuenta jano</p>
                    <div>
                        <label class="input-group mb-2">
                            <span><i class="fa-solid fa-envelope text-lg text-gray-700"></i></span>
                            <input v-model="email" type="text" placeholder="miemail@miemail.com" class="input input-bordered" />
                        </label>
                        <label class="input-group">
                            <span><i class="fa-solid fa-key text-lg text-gray-700"></i></span>
                            <input v-model="pass" type="password" placeholder="*********" class="input input-bordered" />
                        </label>
                        <div class="mt-2">
                            <button @click="goBack" class="text-sm italic text-yellow-600">¿Has olvidado la contraseña? <i class="fa-solid fa-user-lock"></i></button>
                        </div>
                    </div>
                    <button @click="loginDeff" class="btn w-full bg-gradient-to-tr from-green-500 to-green-700 mt-8">Entrar <i class="fa-solid fa-right-to-bracket ml-2"></i></button>
                    <button @click="register" class="btn w-full  mt-2">Registrarse <i class="fa-solid fa-id-card ml-2"></i></button>
                </div>
            </div>
            <div v-if = "login === false" class="flex flex-col my-10 items-center h-full animate__animated animate__fadeIn">
                <div class="text-gray-700 mb-10">
                    <img src="../img/logo.png" alt="" class="w-52">
                </div>
                <div>
                    <p class="text-green-600 text-sm text-center mb-2">¡Empecemos!</p>
                    <div>
                        <label class="input-group mb-2">
                            <span><i class="fa-solid fa-envelope text-lg text-gray-700"></i></span>
                            <input v-model = "registername" type="text" placeholder="Daniel Lorenzo Giménez" class="input input-bordered" />
                        </label>
                        <label class="input-group mb-2">
                            <span><i class="fa-solid fa-envelope text-lg text-gray-700"></i></span>
                            <input v-model="registeremail" type="text" placeholder="miemail@miemail.com" class="input input-bordered" />
                        </label>
                        <label class="input-group mb-2">
                            <span><i class="fa-solid fa-key text-lg text-gray-700"></i></span>
                            <input @keypress="txNombres($event)" v-model="registernie" type="text" placeholder="Dni o pasaporte" class="input input-bordered" />
                        </label>
                        <label class="input-group mb-2">
                            <span><i class="fa-solid fa-key text-lg text-gray-700"></i></span>
                            <input v-model="registernumber" type="text" placeholder="675890712" class="input input-bordered" />
                        </label>
                        <label class="input-group mb-2">
                            <span><i class="fa-solid fa-key text-lg text-gray-700"></i></span>
                            <input v-model="registerpass" type="password" placeholder="Contraseña" class="input input-bordered" />
                        </label>
                        <label class="input-group mb-2">
                            <span><i class="fa-solid fa-key text-lg text-gray-700"></i></span>
                            <input v-model="registerpassrepeat" type="password" placeholder="Repetir contraseña" class="input input-bordered" />
                        </label>
                    </div>
                    <button @click="registerDeff()" class="btn w-full bg-gradient-to-tr from-green-500 to-green-700 mt-10">Registrarse <i class="fa-solid fa-right-to-bracket ml-2"></i></button>
                    <button @click="register()" class="btn w-full bg-gray-100 text-gray-700  mt-2 hover:bg-gray-300 mb-10"><i class="fa-solid fa-arrow-left mr-2"></i> atrás</button>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import { firebaseAutenticate, fireBaseSignIn } from "../db/signIn"
import { Preferences } from '@capacitor/preferences';
import moment from "moment"
import { baseConnect, baseConnectPut } from "../db/db"
import { IonPage, IonContent, toastController } from '@ionic/vue';
import { useRouter } from "vue-router"
import { nanoid } from "nanoid"
export default {
    data(){
        return{
            passboolean : true,
            login : true,
            email : "",
            pass : "",
            registername : "",
            registernie : "",
            registeremail : "",
            registernumber : "",
            registerpass : "",
            registerpassrepeat : "",
            msg : "",
            router : useRouter()
        }
    },
    components:{
        IonPage,
        IonContent
    },
    methods:{
        register(){
            if(this.login === true){
                this.login = false
            }else{
                this.login = true
            }
        },
        passbool(){
            if(this.passboolean === true){
                this.passboolean = false
            }else{
                this.passboolean = true
            }
        },
        async registerDeff(){
            if(this.registernumber.length < 8 || this.registername === "" || this.registeremail.length < 6 || this.registerpass.length < 8 || this.registernie.length <= 8){
                this.presentToast("Porfavor, rellene los datos correctamente. 🤔")
            }else{
                if(this.registerpass === this.registerpassrepeat){
                    const id = nanoid()
                    let objeto = {
                        index : id,
                        register : moment().format('L'),
                        nie : this.registernie.toUpperCase(),
                        name : this.registername.toUpperCase(),
                        password : this.registerpass,
                        number : this.registernumber,
                        email : this.registeremail.toLowerCase()
                    }
                    const datos = await firebaseAutenticate("user", "email" , objeto)
                    if(datos === false){
                        this.presentToast("Error de registro, email existente o fallo de conexión.")
                    }else{
                        this.presentToast("¡Registrado! 🥳")
                        await Preferences.set({
                            key: 'email',
                            value: `${this.registeremail.toLowerCase()}`
                        });
                        await Preferences.set({
                            key: 'nie',
                            value: `${this.registernie.toUpperCase()}`
                        });
                        await Preferences.set({
                            key: 'name',
                            value: `${this.registername}`
                        });
                        await Preferences.set({
                            key: 'phone',
                            value: `${this.registernumber}`
                        });
                        await Preferences.set({
                            key: 'index',
                            value: `${id}`
                        });
                        this.router.push("/inicio")
                    }
                }else{
                    this.presentToast("Las contraseñas no coinciden.")
                }
            }
        },
        async loginDeff(){
            if(this.email.length < 4 || this.pass.length < 4){
                this.presentToast("Campos vacios o demasiado cortos. 😔")
            }else{
                let boolean = true
                this.presentToast("Verificando datos.")
                const datos = await fireBaseSignIn("user", "email", (this.email).toLowerCase(), this.pass)
                if(datos === false){
                    this.presentToast("Email no encontrado, contraseña erronea o error de conexión")
                }else{
                        boolean = false
                        this.presentToast("¡Entrando! 🥳")
                        await Preferences.set({
                            key: 'email',
                            value: `${datos.email.toLowerCase()}`
                        });
                        await Preferences.set({
                            key: 'nie',
                            value: `${datos.nie.toUpperCase()}`
                        });
                        await Preferences.set({
                            key: 'name',
                            value: `${datos.name}`
                        });
                        await Preferences.set({
                            key: 'phone',
                            value: `${datos.number}`
                        });
                        await Preferences.set({
                            key: 'index',
                            value: `${datos.index}`
                        });
                        this.router.push("/inicio")
                }
            }
        },
        async presentToast(msg) {
            const toast = await toastController.create({
                message: msg,
                duration: 2000,
                position: "top"
            });

            await toast.present();
        },
        txNombres(event) {
            if ((event.keyCode != 32) && (event.keyCode < 48) || (event.keyCode > 57) && (event.keyCode < 65) || (event.keyCode > 90) && (event.keyCode < 97) || (event.keyCode > 122))
                event.returnValue = false;
        },
        goBack(){
            this.router.push("/pass")
        }
    }
}
</script>

<style>

</style>